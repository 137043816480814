import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Hero } from "../components/hero"

export default function Seo() {
  return (
    <Layout>
      <Hero image="https://static-cdn.ateros.fr/file/ateros/94mu77gQDsQtdMYhB481lINHg8RBiAjF5OmhgrjF.png">
        <div className="badge badge-pill border border-light text-light">Ateros</div>
        <h1>A propos</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Accueil</a></li>
          <li className="breadcrumb-item active" aria-current="page">Qui sommes nous?</li>
        </ol>
      </Hero>

      <section id="content">

        <div className="content-wrap py-0 overflow-visible">

          <div className="card mx-auto mw-md border-0 shadow rounded-xl card-seo-about mb-0 mb-lg-0">
            <div className="card-body p-5">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="heading-block border-bottom-0 mb-0 text-center">
                    <h3 className="nott ls0 mb-3">Développer des solutions innovantes,<br/> au service des entreprises.
                    </h3>
                    <p>Ateros développe depuis 2018 des solutions innovantes, flexibles et adaptées à vos différentes
                      problématiques. Nos produits sont développés en fonction des besoins réels des professionnels.
                      Notre force réside dans un support client réactif et un développement sur-mesure de nos produits
                      pour satisfaire vos besoins.</p>

                    <p>Notre infrastructure comporte 14 produits qui réunissent +250 professionnels et les accompagnent
                      au quotidien.</p>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="container clearfix">
            <h3 className="nott ls0 mb-3 text-center">Nos valeurs</h3>
            <p className="text-center mb-5 mt-0">Nous pensons qu'avec des solutions numériques de qualité,<br/> il est
              simple de toucher une nouvelle clientèle sur Internet.</p>
            <div className="row col-mb-50 mb-0">

              <div className="col-lg-4">

                <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
                  <h4>> <span>Transparence</span>.</h4>
                </div>

                <p>La relation de confiance avec nos clients est primordiale pour réussir à développer une solution
                  numérique satisfaisante. C'est pourquoi nous appliquons un principe de transparence vis-à-vis de ces
                  derniers.</p>
              </div>

              <div className="col-lg-4">

                <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
                  <h4>> <span>Qualité</span>.</h4>
                </div>

                <p>Si s'aventurer dans le monde du numérique peut parfois sember fastidieux, c'est souvent dû à une
                  mauvaise expérience utilisateur. Nous sommes fier de proposer des services avec une UX fluide pour
                  tout le monde.</p>
              </div>

              <div className="col-lg-4">

                <div className="heading-block fancy-title border-bottom-0 title-bottom-border">
                  <h4>> <span>Innovation</span>.</h4>
                </div>

                <p>Les produits que nous proposons sont développés avec une idée en tête : combler un manque que nous
                  avons ressenti dans le monde du numérique. L'innovation est au coeur de notre mentalité.</p>
              </div>

            </div>

          </div>
          <div className="section bg-transparent mt-0">
            <div className="container"><h3 className="nott ls0 mb-3 text-center mb-5">Nos services</h3>
              <div className="row col-mb-30 align-content-stretch">

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/dL60a8aKpiJeh3Kd6Nk3o7P3CDHbniOWUqh9jFw3.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Designer</h3>
                          <p className="text-smaller">Proposez du Print On Demand avec un outil adapté et
                            performant.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/GXNm9p1IEIB05jyiuGsKMVqLMJJv3MIrrB7XjCnA.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Social</h3>
                          <p className="text-smaller">Création de contenu pour tous vos réseaux sociaux.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/3n9z4XHE07Mwk2PJeBgbpT0DawEhtl57TmEGVM9y.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Shop</h3>
                          <p className="text-smaller">Développez votre business avec un site moderne et élégant.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/zxHDIcdNYcyoT9EXupwmykBZRUfSzl119cQw255f.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Invoice</h3>
                          <p className="text-smaller">Facturation nouvelle génération.
                            Découvrez Ateros Invoice.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/a7k4MNguDKbFPFHeA6UJSCOmApba4HLZgRcxv6sV.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Pay</h3>
                          <p className="text-smaller">Acceptez les paiements en ligne sur toutes vos plateformes.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-lg-3 col-md-6 d-flex text-center">
                  <a href="demo-seo-experience.html" className="card h-shadow h-translate-y-sm all-ts">
                    <div className="card-body p-5">
                      <div className="feature-box flex-column">
                        <div className="fbox-image mb-5 text-center">
                          <StaticImage height="150"
                               src="https://static-cdn.ateros.fr/file/ateros/iA7XlPpBk5qnGfWIYbK2HuO6RfdVn7Fnr5T55CYR.png"
                               alt="Feature Icon"/>
                        </div>
                        <div className="fbox-content">
                          <h3 className="nott ls0 text-larger">Ateros Host</h3>
                          <p className="text-smaller">Hébergement d'application et site web.
                            Serveurs virtuels, hébergement web et mail.</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>


              </div>
            </div>
          </div>

          <div className="section bg-transparent mt-0">
            <h3 className="nott ls0 mb-3 text-center">Notre équipe</h3>
            <p className="text-center mb-5 mt-0">Une équipe dédiée pour tous vos projets et vous accompagner au
              quotidien.</p>

            <div className="container">

              <div className="fancy-title title-border">
                <h3>Direction</h3>
              </div>

              <div className="row col-mb-50 mb-0">

                <div className="col-lg-6">

                  <div className="team team-list row align-items-center">
                    <div className="team-image col-sm-6">
                      <StaticImage src="https://static.ateros.fr/omvz3edSLMG7aCb61u4Fh0AYTRfQWr.jpeg" alt="John Doe"/>
                    </div>
                    <div className="team-desc col-sm-6">
                      <div className="team-title"><h4>Grégory Julien</h4><span>Responsable commercial et projets.</span>
                      </div>
                      <div className="team-content">
                        <p>L'innovation est pour moi essentielle dans un domaine comme le nôtre, le numérique. La
                          création de solution sur-mesure
                          pour les entreprises et répondre à une demande concrète des clients est mon quotidien.</p>
                        <a href="" className="mt-3">g.julien@ateros.fr</a>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-6">

                  <div className="team team-list row align-items-center">
                    <div className="team-image col-sm-6">
                      <StaticImage src="https://pbs.twimg.com/profile_images/1131150405164048384/WdaVfs53_400x400.jpg"
                           alt="Nix Maxwell"/>
                    </div>
                    <div className="team-desc col-sm-6">
                      <div className="team-title"><h4>Antonin Guyot</h4><span>Responsable developpement, réseaux et infrastructure.</span>
                      </div>
                      <div className="team-content">
                        <p>Eradicate invest honesty human rights accessibility theory of social change. Diversity
                          experience in the field compassion, inspire breakthroughs planned giving.</p>
                        <a href="" className="mt-3">a.guyot@ateros.fr</a>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

              <div className="fancy-title title-border">
                <h3>Service Commercial</h3>
              </div>

              <div className="row col-mb-50 mb-0">

                <div className="col-lg-6">

                  <div className="team team-list row align-items-center">
                    <div className="team-image col-sm-6">
                      <StaticImage src="https://static-cdn.ateros.fr/file/ateros/LkGIzyCjpVPhHiXDfTVnkJe3v1YDDEnzwYGS2pJt.jpg"
                           alt="John Doe"/>
                    </div>
                    <div className="team-desc col-sm-6">
                      <div className="team-title"><h4>Romain Cambois </h4><span>Commercial.</span></div>
                      <div className="team-content">
                        <p>L'innovation est pour moi essentielle dans un domaine comme le nôtre, le numérique. La
                          création de solution sur-mesure
                          pour les entreprises et répondre à une demande concrète des clients est mon quotidien.</p>
                        <a href="" className="mt-3">r.cambois@ateros.fr</a>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-lg-6">

                  <div className="team team-list row align-items-center">
                    <div className="team-image col-sm-6">
                      <StaticImage src="http://simpleicon.com/wp-content/uploads/user1.png" alt="John Doe"/>
                    </div>
                    <div className="team-desc col-sm-6">
                      <div className="team-title"><h4>Vous ? </h4><span>Commercial indépendant.</span></div>
                      <div className="team-content">
                        <p>Ateros recrute un <b>H/F Commercial indépendant - Secteur: France</b>, pour plus
                          d'information sur le poste contactez nous !</p>
                        <a href="" className="mt-3">g.julien@ateros.fr</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div className="fancy-title title-border">
                <h3>Service Communication</h3>
              </div>

              <div className="row col-mb-50 mb-0">

                <div className="col-lg-6">

                  <div className="team team-list row align-items-center">
                    <div className="team-image col-sm-6">
                      <StaticImage src="http://simpleicon.com/wp-content/uploads/user1.png" alt="John Doe"/>
                    </div>
                    <div className="team-desc col-sm-6">
                      <div className="team-title"><h4>Vous ? </h4>
                        <span>Community manager (Responsable communication).</span></div>
                      <div className="team-content">
                        <p>Ateros recrute un <b>H/F Community manager - Secteur: France</b>, pour plus d'information sur
                          le poste contactez nous !</p>
                        <a href="" className="mt-3">g.julien@ateros.fr</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}